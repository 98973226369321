//import '../node_modules/photoswipe/dist/photoswipe.css';
//import '../node_modules/photoswipe/dist/default-skin/default-skin.css';
import 'swiper/swiper-bundle.css';
import '../sdist/tailwind.css';
import './main.scss';
import $ from 'jquery';
import 'popper.js';
import 'bootstrap';
import '../node_modules/photoswipe/dist/photoswipe.css';
import '../node_modules/photoswipe/dist/default-skin/default-skin.css';
import initPhotoSwipeFromDOM from './js/photoswipe.js';


import Swiper, { Navigation, Pagination, Thumbs, Autoplay, EffectFade } from "swiper";
import CookiesEuBanner from 'cookies-eu-banner';
import _ from 'lodash';
import Alpine from 'alpinejs';
import persist from '@alpinejs/persist'
import collapse from '@alpinejs/collapse'

Alpine.plugin(persist)
window.Alpine = Alpine
//import photogallery from './js/photoswipe.js';


Alpine.plugin(collapse);

document.addEventListener('alpine:init', () => {
  Alpine.data('swiper', (options) => ({
    init() {
      new Swiper(this.$refs.swipe, {
        modules: [Pagination, Autoplay, EffectFade, Navigation, Thumbs],
        ...options
      });
    }
  }))
});

Alpine.start()

window.addEventListener('load', () => {

  var hash = location.hash;
  if (hash === '#print') {
    window.print();
  }

  // try {
  //   var photogalleryOptions = {
  //     shareButtons: localizedShareButtons
  //   };
  // } catch (ReferenceError) {
  //   var photogalleryOptions = {};
  // }

  // photogallery({
  //   captionSelector: '.caption',
  //   ...photogalleryOptions
  // });
  $('body').scrollspy({
    target: '#navbar-top',
    offset: 60
  })

  // $('.dropdown.dropdown--keep-open').on({
  //   'shown.bs.dropdown': function () {
  //     $(this).attr('closable', false);
  //   },
  //   'hide.bs.dropdown': function () {
  //     return $(this).attr('closable') == 'true';
  //   }
  // });

  // if ($(window).width() > 769) {
  //   $('.navbar .dropdown > a').click(function () {
  //     location.href = this.href;
  //   });
  // }

  // $('.dropdown.dropdown--keep-open').children().first().on('click', function () {
  //   $(this).parent().attr('closable', true);
  // });



  var homeNewsSwiper = new Swiper('.homenews', {
    modules: [Navigation],
    slidesPerView: 'auto',
    slidesOffsetAfter: 80,
    navigation: {
      prevEl: '.homenews__button--prev',
      nextEl: '.homenews__button--next',
      disabledClass: 'homenews__button--disabled',
    },
  });


  var ModalGalleryThumbs = new Swiper('.gallery-thumbs', {
    modules: [Thumbs],
    slidesPerView: 7,
    freeMode: true,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    breakpoints: {
      // < tablet
      769: {
        slidesPerView: 3,
      },
      // < desktop
      960: {
        slidesPerView: 5,
      }
    }
  });

  var ModalGallerySwiper = new Swiper('.gallery-swiper', {
    modules: [Navigation, Pagination, Thumbs],
    slidesPerView: 1,
    speed: 500,
    navigation: {
      prevEl: '.gallery__arrow--prev',
      nextEl: '.gallery__arrow--next',
      disabledClass: 'gallery__arrow--disabled',
    },
    thumbs: {
      swiper: ModalGalleryThumbs
    }
  });


/* Swiper Slideshow */
var swiper = new Swiper('.swiper-slideshow', {
  modules: [Pagination],
  autoHeight: true,
  pagination: {
    el: '.swiper-slideshow-pagination',
    clickable: true
  },
});

/* Swiper Content Collection */
var swiper = new Swiper('.swiper-content-collection', {
  modules: [Pagination],
  pagination: {
    el: '.swiper-content-collection-pagination',
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + (index + 1) + '</span>';
    },
  },
});

  var GalleryThumbs = new Swiper('.gallery-thumbs', {
    modules: [Thumbs],
    slidesPerView: 7,
    freeMode: true,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    breakpoints: {
      // < tablet
      769: {
        slidesPerView: 3,
      },
      // < desktop
      960: {
        slidesPerView: 5,
      }
    }
  });

  var GallerySwiper = new Swiper('.gallery-swiper', {
    modules: [Navigation, Pagination, Thumbs],
    slidesPerView: 1,
    speed: 500,
    navigation: {
      prevEl: '.gallery__arrow--prev',
      nextEl: '.gallery__arrow--next',
      disabledClass: 'gallery__arrow--disabled',
    },
    thumbs: {
      swiper: GalleryThumbs
    }
  });
});

document.addEventListener('DOMContentLoaded', () => {
  // Get all "navbar-burger" elements
  const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

  // Check if there are any navbar burgers
  if ($navbarBurgers.length > 0) {

    // Add a click event on each of them
    $navbarBurgers.forEach(el => {
      el.addEventListener('click', () => {

        // Get the target from the "data-target" attribute
        const target = el.dataset.target;
        const $target = document.getElementById(target);

        // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
        el.classList.toggle('is-active');
        $target.classList.toggle('is-active');

      });
    });
  }


  if (document.getElementsByClassName('gallery-main').length) {
    var galleryThumbs = {};

    if (document.getElementsByClassName('gallery-thumbs').length) {
      galleryThumbs = {swiper: new Swiper('.gallery-thumbs', {
        modules: [Pagination],
        spaceBetween: 20,
        slidesPerView: 4,
        freeMode: true,
        navigation: {
          nextEl: '.gallery-thumbs-next',
          prevEl: '.gallery-thumbs-prev',
        },
        loop: false,
        centeredSlides: false,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        slideToClickedSlide: true,
        preloadImages: false,
        freeModeSticky: true,
        // Enable lazy loading
        lazy: true,
        breakpoints: {
          // others are 640px, 768px, 1024px, 1280px see tailwind.config.js
          // TODO: import from tailwind
          // 768: {
          //   slidesPerView: 2,
          //   spaceBetween: 20,
          //   direction: 'vertical',
          // },
        }
      })};
    }

    var galleryTop = new Swiper('.gallery-main', {
      spaceBetween: 0,
      preloadImages: false,
      pagination: {
        el: '.gallery-main-indicator',
        type: 'fraction',
      },
      // Enable lazy loading
      lazy: true,
      thumbs: galleryThumbs
    });

    initPhotoSwipeFromDOM('.gallery-main');
  }

  initPhotoSwipeFromDOM('.gallery-line');
  initPhotoSwipeFromDOM('.gallery-items');
});
